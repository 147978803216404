import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { CanDeactivate, Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { Observable } from 'rxjs'
import { ComformationDialogComponent } from 'src/app/shared/components/comformation-dialog/comformation-dialog.component'

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>
}

@Injectable({
  providedIn: 'root'
})
export class DirtyFormGuard implements CanDeactivate<ComponentCanDeactivate> {
  constructor(
    private readonly dialog: MatDialog,
    private readonly translate: TranslateService,
    private router: Router
  ) {}

  canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> {
    const result = component.canDeactivate()

    if (result instanceof Observable) {
      return result // If canDeactivate returns an Observable, return it directly
    }

    if (result) {
      return true // If canDeactivate returns true synchronously, allow navigation
    }

    const dialogRef = this.dialog.open(ComformationDialogComponent, {
      data: {
        title: this.translate.instant('PROMPTMESSAGE.unsavedChanges'),
        body: this.translate.instant('PROMPTMESSAGE.unsavedPrompt'),
        okButtonName: this.translate.instant('BTN.continue'),
        cancelButtonName: this.translate.instant('BTN.cancel')
      }
    })

    return new Observable<boolean>(observer => {
      dialogRef.afterClosed().subscribe(dialogResult => {
        if (dialogResult?.respone) {
          observer.next(true) // If user confirmed, allow navigation
        } else {
          observer.next(false) // If user canceled, prevent navigation
        }
        observer.complete()
      })
    })
  }
}
