import { HttpClientModule } from '@angular/common/http'
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'

@NgModule({
  imports: [BrowserAnimationsModule, HttpClientModule],
  exports: [BrowserAnimationsModule],
  declarations: []
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() private coreModule: CoreModule) {
    if (coreModule) {
      throw new Error('Core module cannot be imported more than once')
    }
  }
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: []
    }
  }
}
