import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Router, RouterStateSnapshot } from '@angular/router'
import { Services } from 'src/app/shared/constants/services'
import { StorageKeys } from 'src/app/shared/constants/storage-keys'
import { ServiceDetail } from 'src/app/shared/models/serviceDetail'

import { CoreModule } from '../../core.module'
import { AuthService } from '../auth/auth.service'
import { LocalStorageService } from '../storage/local-storage.service'

@Injectable({
  providedIn: CoreModule
})
export class AuthGuardService implements CanLoad, CanActivate {
  canLoad(): boolean {
    if ((this.authService.keycloak && this.authService.keycloak.authenticated) || this.authService.isLoggedIn) {
      return true
    }
    return false
  }

  canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.authService.keycloak.authenticated || this.authService.isLoggedIn) {
      if (!this.checkPermissions(state)) {
        this.router.navigate(['']) // canActivate=>false results in blank screen/dead link
        return false
      }

      return true
    }
    return false
  }

  checkPermissions(state: RouterStateSnapshot): boolean {
    const route: string = state.url
    const userPermissionAvailed = this.storage.get(StorageKeys.userPermissions)

    if (
      route.includes('client') ||
      route.includes('lms') ||
      route.includes('search') ||
      route.includes('self') ||
      route.includes('category') ||
      route.includes('keywords')
    ) {
      return true
    } else if (route.includes('user')) {
      if (route.includes('user-management')) {
        return !!userPermissionAvailed['canManageUsers']
      } else if (route.includes('create')) {
        return !!userPermissionAvailed['canCreateUsers']
      }
      return false
    } else if (route.includes('miscellaneous')) {
      return !!userPermissionAvailed['miscellaneousAccess']
    } else if (route.includes('document-library')) {
      return !!userPermissionAvailed['docLibraryAccess']
    } else if (route.includes('settings')) {
      return !!userPermissionAvailed['orgSettingsAccess']
    } else if (route.includes('channels')) {
      return !!userPermissionAvailed['canManageUsers']
    } else {
      const servicesAvailed = (this.storage.get(StorageKeys.serviceDetails) || []) as ServiceDetail[]
      const r = route.split('/')[1]
      const service = Services.find(service => {
        return service.route.find(serviceRoute => serviceRoute.url?.includes(r))
      })

      const permission = servicesAvailed.find(s => {
        return service?.serviceCode == s?.service?.code
      })
      return !!permission?.write
    }
  }

  constructor(
    private readonly storage: LocalStorageService,
    private authService: AuthService,
    public readonly router: Router
  ) {}
}
