<div class="dialog-head">
  <h3 class="m-0">{{ 'SESSION.SESSION' | translate }}</h3>
</div>
<div mat-dialog-content>
  <p class="mt-3">{{ 'SESSION.whichSessionToKeep' | translate }}</p>

  <div class="row justify-content-end align-items-center gap-1">
    <button class="primary-btn" (click)="response(true)">
      {{ 'SESSION.YES' | translate }}
    </button>

    <button class="secondary-btn" (click)="response(false)">
      {{ 'SESSION.NO' | translate }}
    </button>
  </div>
</div>
