import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { NgxJitsuService } from '@jitsu/angular'

import { LocalStorageService } from '../storage/local-storage.service'
import { UtilitiesService } from '../utilities/utilities.service'

@Injectable({
  providedIn: 'root'
})
export class JitsuService {
  track(eventName: string, data) {
    this.jitsuTracker.track(eventName, {
      body: {
        eventDetails: data
      }
    })
  }
  constructor(
    private router: Router,
    public http: HttpClient,
    private route: ActivatedRoute,
    private jitsuTracker: NgxJitsuService,
    private utilitiesService: UtilitiesService,
    private readonly storage: LocalStorageService
  ) {
    // router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: any) => {
    //   this.jitsuTracker.trackPageView()
    // })
  }
  setUserInfoInJitsu(data) {
    const currentUrl = window.location.href
    const source = this.utilitiesService.getSource(currentUrl)
    data.source = source
    this.jitsuTracker.id(data)
  }
}
