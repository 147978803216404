export const SessionStorageKeys = {
  useremail: 'useremail',
  dtTitle: 'dtTitle',
  service: 'service',
  language: 'language',
  origin: 'origin',
  channel: 'channel',
  articleTitle: 'articleTitle',
  quizData: 'quizData',
  decisionTreeTitle: 'decisionTreeTitle',
  faqTitle: 'faqTitle',
  tickTitle: 'tickTitle',
  broadcastTitle: 'broadcastTitle',
  bannerTitle: 'bannerTitle',
  pictureGuideTitle: 'pictureGuideTitle',
  selectedLanguage: 'selectedLanguage',
  templateContent: 'templateContent'
}
