import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { Router } from '@angular/router'
import { UnleashService } from '@snowfrog/ngx-unleash-proxy-client'
import jwtDecode from 'jwt-decode'
import Keycloak from 'keycloak-js'
import * as cloneDeep from 'lodash/cloneDeep'
import { UserEntryService } from 'src/app/dashboard/components/user/pages/user-entry/user-entry.service'
import { SessionComponent } from 'src/app/shared/components/session-dialog/session-dialog.component'
import { AnalyticsEvents } from 'src/app/shared/constants/jitsu-analytics-keys'
import { StorageKeys } from 'src/app/shared/constants/storage-keys'
import { ServiceName } from 'src/app/shared/models/other-module'
import { environment } from 'src/environments/environment'

import { CoreModule } from '../../core.module'
import { ApiService } from '../http/api.service'
import { JitsuService } from '../jitsu/jitsu.service'
import { LocalStorageService } from '../storage/local-storage.service'
import { SessionStorageService } from '../storage/session-storage.service'

@Injectable({
  providedIn: CoreModule
})
export class AuthService {
  keycloak!: Keycloak
  constructor(
    private api: ApiService,
    private service: UserEntryService,
    private dialog: MatDialog,
    private unleashService: UnleashService,
    private readonly jitsu: JitsuService,
    private router: Router,
    private readonly storage: LocalStorageService,
    private readonly sessionStorage: SessionStorageService
  ) {}
  get profile() {
    return {
      ...this.keycloak?.profile,
      id: this.userInfo['sub'],
      roles: this.userInfo['realm_access']['roles']
    }
  }
  private get userInfo() {
    return this.keycloak?.userInfo
  }
  get isLoggedIn() {
    return !(this.keycloak?.isTokenExpired ?? true)
  }
  refreshToken(cb: () => void, errCb: () => void) {
    this.keycloak.updateToken(30).then(cb).catch(errCb)
  }

  public getUserInfo() {
    return new Promise((resolve, reject) => {
      const res = this.api.sendRequest({
        apiBase: `${environment.apiBase}/api/v2/`,
        method: 'get',
        endpoint: 'users/ui-info'
      })
      res.subscribe({
        next: data => {
          // handle the case if the user is inactive on km-app, but login via sso integration displays error info component
          if (data?.code) {
            resolve(data)
          } else {
            this.storage.set(StorageKeys.authToken, data.authToken)
            const decodedData: { isAdmin: boolean } = jwtDecode(data.authToken)
            if (environment.subdomain !== 'master') {
              const userInfo = cloneDeep(data)
              delete userInfo?.authToken
              this.jitsu.setUserInfoInJitsu(userInfo)
              this.jitsu.track(AnalyticsEvents.userInformation, userInfo)
            }
            if ('isAdmin' in decodedData) data['isAdmin'] = decodedData.isAdmin
            const isSessionEnabled = this.unleashService.isEnabled(ServiceName.session)
            if (isSessionEnabled) {
              this.api
                .sendRequest({
                  apiBase: `${environment.apiBase}/api/v2/`,
                  method: 'get',
                  endpoint: `users/session-count/${environment.keycloakClientId}`
                })
                .subscribe(count => {
                  if (count >= 2) {
                    const dialogRef = this.dialog.open(SessionComponent, {
                      width: '600px',
                      disableClose: true,
                      data: {
                        authToken: data.authToken
                      }
                    })
                    dialogRef.afterClosed().subscribe(result => {
                      if (!result) this.keycloak.logout()
                      resolve(data)
                    })
                  } else {
                    resolve(data)
                  }
                })
            } else {
              resolve(data)
            }
          }
        },
        error: err => {
          reject(err)
        }
      })
    })
  }

  async logout() {
    return new Promise((resolve, reject) => {
      this.service.logout().subscribe({
        next: async () => {
          try {
            this.sessionStorage.clearAll()
            this.storage.clearAll()
            await this.keycloak.logout()
            resolve(true)
          } catch (err) {
            reject(err)
          }
        },
        error: err => reject(err)
      })
    })
  }

  public setUserInfoMessage(errorType: string) {
    this.service.setValue(errorType)
    this.router.navigate(['error'])
  }

  public getCompanyInfo() {
    try {
      return this.api.sendRequest({
        apiBase: `${environment.apiBase}/api/v2/`,
        method: 'get',
        endpoint: 'company/profile'
      })
    } catch (err) {
      console.log(err)
    }
  }
}
