export const StorageKeys = {
  sessionId: 'sessionId',
  userInfo: 'userInfo',
  serviceDetails: 'serviceDetails',
  userPermissions: 'userPermissions',
  companyProfile: 'companyProfile',
  languages: 'languages',
  departments: 'departments',
  selectedDepartment: 'selectedDepartment',
  token: 'token',
  refreshToken: 'refreshToken',
  dashboardTiles: 'dashboardTiles',
  selectedTheme: 'selectedTheme',
  hideCookieSidenav: 'hideCookieSidenav',
  currentCommand: 'currentCommand',
  selectedLanguage: 'selectedLangauge',
  isServiceContent: 'isServiceContent',
  languageContents: 'languageContents',
  languageContentToOpen: 'languageContentToOpen',
  currentTheme: 'currentTheme',
  authToken: 'authToken',
  servicePermissions: 'servicePermissions',
  isClientEnabled: 'isClientEnabled'
}
