import Keycloak from 'keycloak-js'
import { StorageKeys } from 'src/app/shared/constants/storage-keys'
import { environment } from 'src/environments/environment'

import { AuthService } from '../auth/auth.service'
import { LocalStorageService } from '../storage/local-storage.service'

export const initializeKeycloak = (authService: AuthService, storage: LocalStorageService) => {
  return () => {
    // realm name will be the subdomain
    const keycloak: Keycloak = new Keycloak({
      realm: environment.keycloakRealm,
      url: environment.keycloakBaseUrl,
      clientId: environment.keycloakClientId
    })

    return keycloak
      .init({
        onLoad: 'login-required',
        checkLoginIframe: false
      })
      .then(async () => {
        authService.keycloak = keycloak
        const response = await authService.getUserInfo()
        if (response['code'] && response['code'] == 401) {
          authService.setUserInfoMessage('AccountDisabled')
          return false
        }
        return authService.keycloak
          .loadUserInfo()
          .then(() => {
            // return authService.keycloak.loadUserProfile()
            if (authService.keycloak.authenticated) return authService.keycloak.loadUserProfile()
          })
          .then(() => {
            setStorageValues(response)
            authService.getCompanyInfo().subscribe(response => {
              if (response) {
                // environment.jitsuClientKey = response.jitsuClientKey

                // environment.jitsuTrackingHost = response.jitsuTrackingHost

                delete response.jitsuClientKey
                delete response.jitsuTrackingHost
                storage.set(StorageKeys.companyProfile, response)
              }
            })
            return true
          })
      })
      .catch(() => false)
  }

  function setStorageValues(response) {
    if (!response.isAdmin) {
      authService.setUserInfoMessage('NotAdmin')
      return
    }

    const userinfo = {
      _id: response?.id,
      isMasterEntry: response?.isMasterEntry,
      status: response?.status,
      emailId: response?.emailId,
      fullName: response?.fullName,
      username: response?.username,
      isAdmin: response?.isAdmin
    }
    const role = response?.role
    const permissions = {
      isApprover: role?.isApprover,
      canCreateUsers: role?.isCreateUser,
      canManageUsers: role?.isManageUsers,
      miscellaneousAccess: role?.miscellaneousAccess,
      docLibraryAccess: role?.docLibraryAccess,
      orgSettingsAccess: role?.orgSettingsAccess,
      dtSessionRead: role?.dtSessionRead,
      dtSessionDelete: role?.dtSessionDelete,
      selfServiceAccess: role?.selfServiceAccess
    }
    storage.set(StorageKeys.userPermissions, permissions)
    storage.set(StorageKeys.departments, role?.departments.map(item => item.id) || [])
    storage.set(StorageKeys.authToken, response.authToken)
    storage.set(StorageKeys.userInfo, userinfo)
    storage.set(StorageKeys.serviceDetails, role?.servicePolicies || [])

    // if (!response.isAdmin) {
    //   authService.setUserInfoMessage('NotAdmin')
    // }
  }
}
