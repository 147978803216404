import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'

import { AuthGuardService } from './core/services/account/auth-guard.service'
import { NotFoundComponent } from './not-found/not-found.component'

const routes: Routes = [
  {
    path: 'forgot',
    loadChildren: () => import('./forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule)
  },
  {
    path: 'reset',
    loadChildren: () => import('./reset-password/reset-password.module').then(m => m.ResetPasswordModule)
  },
  {
    path: 'client',
    loadChildren: () => import('./client/client.module').then(m => m.ClientModule)
  },
  {
    path: 'error',
    loadChildren: () => import('./error-info/error-info.module').then(m => m.ErrorInfoModule)
  },
  {
    path: 'api',
    loadChildren: () => import('./api/api.module').then(m => m.ApiModule),
    canLoad: [AuthGuardService]
  },
  {
    path: 'i-api',
    loadChildren: () => import('./i-api/i-api.module').then(m => m.IApiModule),
    canLoad: [AuthGuardService]
  },
  {
    path: '',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    canLoad: [AuthGuardService]
  },

  { path: '**', component: NotFoundComponent }
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
