<div>
  <div class="container">
    <div class="row align-items-center">
      <div class="col-md-6 d-flex align-items-center justify-content-center">
        <img src="assets/images/login_bg.png" class="img-fluid" alt="Login" width="80%" />
      </div>
      <div class="col-md-6">
        <div class="error-info">
          <div class="error-message">
            <div class="border-bottom">
              <p class="error-message__msg">{{ 'ERRORINFO.NotFound.text' | translate }}</p>
            </div>
            <p class="error-message__msg--secondary">
              {{ 'ERRORINFO.NotFound.info' | translate }}
            </p>
            <button class="primary-btn" (click)="goBack()">
              {{ 'ERRORINFO.NotFound.btnText' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <footer class="d-flex justify-content-center mt-2 align-items-center gap-1 footer-links">
    <a href="mailto:support@know-max.com" class="span_grey">Email us: support@know-max.com</a>
    &nbsp;|&nbsp;
    <span class="span_grey">All rights reserved</span>
    &nbsp; | &nbsp;
    <a href="#" class="span_grey">Privacy-policy </a> &nbsp;|&nbsp;
    <a href="#" class="span_grey">Terms & conditions</a>
  </footer>
</div>
