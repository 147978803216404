// Generated by https://quicktype.io

export interface KtechHttpResponse {
  error: Error
  result: any
  statusCode: KtechHttpResponseStatus
}

export interface Error {
  messages: any[]
}
export enum KtechHttpResponseStatus {
  OK = 200,
  CREATED = 201
}
