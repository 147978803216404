import { TranslateService } from '@ngx-translate/core'

export function retrieveAndSetLanguage(
  langCode: string,
  translate: TranslateService,
  prefix = '../assets/i18n/',
  suffix = '.json'
) {
  if (langCode)
    import(`../../../assets/i18n/${langCode}${suffix}`).then(res => translate.setTranslation(langCode, res.default))
}
