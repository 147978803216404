import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'
import { ApiService } from 'src/app/core/services/http/api.service'
import { environment } from 'src/environments/environment'

@Injectable({
  providedIn: 'root'
})
export class UserEntryService {
  message
  private routerInfo: BehaviorSubject<string>
  constructor(private readonly api: ApiService) {
    this.routerInfo = new BehaviorSubject<string>('')
  }

  public addUser(body) {
    return this.api.sendRequest({
      apiBase: `${environment.apiBase}/api/v2/`,
      method: 'post',
      endpoint: 'users',
      body
    })
  }

  logout() {
    return this.api.sendRequest({
      apiBase: `${environment.apiBase}/api/v2/`,
      method: 'post',
      endpoint: `users/logout`
    })
  }
  public addBulkUsers(body, isChanged) {
    return this.api.sendRequest({
      apiBase: `${environment.apiBase}/api/v2/`,
      method: 'post',
      endpoint: 'users/bulk-create',
      body,
      queryParams: {
        tempPassword: isChanged
      }
    })
  }

  editUser(body, id) {
    return this.api.sendRequest({
      apiBase: `${environment.apiBase}/api/v2/`,
      method: 'put',
      endpoint: `users/${id}`,
      body
    })
  }

  toogleStatusUser(body, id, flag) {
    if (flag) {
      return this.api.sendRequest({
        apiBase: `${environment.apiBase}/api/v2/`,
        method: 'put',
        endpoint: `users/active/${id}`,
        body
      })
    } else {
      return this.api.sendRequest({
        apiBase: `${environment.apiBase}/api/v2/`,
        method: 'put',
        endpoint: `users/inactive/${id}`,
        body
      })
    }
  }

  public getActiveDepartments({ pageIndex, pageSize, sort }: { pageIndex: number; pageSize: number; sort: number }) {
    return this.api.sendRequest({
      apiBase: `${environment.apiBase}/api/v2/`,
      method: 'get',
      endpoint: 'departments/active',
      queryParams: {
        page: pageIndex + 1,
        limit: pageSize,
        sort: sort
      }
    })
  }

  public checkIfTempBlocked(username: string) {
    return this.api.sendRequest({
      apiBase: `${environment.apiBase}/api/v2/`,
      method: 'get',
      endpoint: `users/brute-force-check/${username}`
    })
  }

  public removeTempBlocked(username: string) {
    return this.api.sendRequest({
      apiBase: `${environment.apiBase}/api/v2/`,
      method: 'delete',
      endpoint: `users/brute-force/${username}`
    })
  }

  getUser(
    {
      pageIndex,
      pageSize
    }: {
      pageIndex: number
      pageSize: number
    },
    status: number
  ) {
    return this.api.sendRequest({
      apiBase: `${environment.apiBase}/api/v2/`,
      method: 'get',
      endpoint: 'users',
      queryParams: {
        page: pageIndex + 1,
        limit: pageSize,
        status
      }
    })
  }

  getUserById(id) {
    return this.api.sendRequest({
      apiBase: `${environment.apiBase}/api/v2/`,
      method: 'get',
      endpoint: `users/${id}`
    })
  }
  setValue(newValue: string): void {
    this.routerInfo.next(newValue)
  }
  getValue(): Observable<string> {
    return this.routerInfo.asObservable()
  }
  setHeartBeat() {
    return this.api.sendRequest({
      apiBase: `${environment.apiBase}/api/v2/`,
      method: 'get',
      endpoint: 'users/heartbeat'
    })
  }
  getSheetNames(body) {
    return this.api.sendRequest({
      apiBase: `${environment.apiBase}/api/v2/`,
      method: 'post',
      endpoint: `users/xlsx/pages`,
      body
    })
  }
  validateSheet(body) {
    return this.api.sendRequest({
      apiBase: `${environment.apiBase}/api/v2/`,
      method: 'post',
      endpoint: `users/xlsx/validate`,
      body
    })
  }
}
