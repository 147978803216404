import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

import { SessionDialogService } from './session-dialog.service'

@Component({
  selector: 'app-language-dialog',
  templateUrl: './session-dialog.component.html',
  styleUrls: ['./session-dialog.component.scss']
})
export class SessionComponent {
  authToken: string
  constructor(
    public dialogRef: MatDialogRef<SessionComponent>,
    private sessionDialogService: SessionDialogService,
    @Inject(MAT_DIALOG_DATA) public data: { authToken: string }
  ) {
    this.authToken = data.authToken
  }

  response(choice: boolean) {
    this.sessionDialogService.logOutSession(choice, this.authToken).subscribe({
      next: () => {
        this.dialogRef.close(choice)
      }
    })
  }
}
