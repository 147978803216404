export const AnalyticsEvents = {
  searchingContent: 'searchingContent',
  userInformation: 'userInformation',
  contentPublished: 'contentPublished',
  articleEdited: 'articleEdited',
  faqEdited: 'faqEdited',
  lmsContentPublished: 'lmsContentPublished',
  lmsContentDeleted: 'lmsContentDeleted',
  lmsUndoDeletedContent: 'lmsUndoDeletedContent',
  contentDeleted: 'contentDeleted',
  pictureGuideEdited: 'pictureGuideEdited',
  undoDeletedContent: 'undoDeletedContent',
  articleCreated: 'articleCreated',
  faqCreated: 'faqCreated',
  pictureGuideCreated: 'pictureGuideCreated',
  decisiontreeCreated: 'decisiontreeCreated',
  decisiontreeCloned: 'decisiontreeCloned',
  contentUploadedForApproval: 'contentUploadedForApproval',
  contentArchived: 'contentArchived'
}
