import { Injectable } from '@angular/core'
import { ApiService } from 'src/app/core/services/http/api.service'
import { environment } from 'src/environments/environment'

@Injectable({
  providedIn: 'root'
})
export class SessionDialogService {
  constructor(private readonly api: ApiService) {}
  logOutSession(sessionDelete: boolean, authToken: string) {
    return this.api.sendRequest({
      apiBase: `${environment.apiBase}/api/v2/`,
      method: 'get',
      endpoint: `users/session/logout/${environment.keycloakClientId}`,
      headers: {
        'auth-token': authToken
      },
      queryParams: {
        sessionDelete: sessionDelete.toString()
      }
    })
  }
}
