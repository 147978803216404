import { Injectable } from '@angular/core'
import { environment } from 'src/environments/environment'

import { CoreModule } from '../../core.module'

@Injectable({
  providedIn: CoreModule
})
export class LocalStorageService {
  public set(key: string, value: object | string | number | Date | Array<any> | boolean) {
    localStorage.setItem(this.encodeKey(`${environment.subdomain}-${key}`), this.encodeValue(value))
  }
  public get(key: string): object | string | number | Date | Array<any> | boolean {
    const localValue = localStorage.getItem(this.encodeKey(`${environment.subdomain}-${key}`))
    if (!localValue) {
      return ''
    }
    return this.decodeValue(localValue)
  }
  public clearAll() {
    localStorage.clear()
  }
  public remove(key) {
    localStorage.removeItem(this.encodeKey(`${environment.subdomain}-${key}`))
  }

  private toBinary(string) {
    const codeUnits = Uint16Array.from({ length: string.length }, (element, index) => string.charCodeAt(index))
    const charCodes = new Uint8Array(codeUnits.buffer)

    let result = ''
    charCodes.forEach(char => {
      result += String.fromCharCode(char)
    })
    return result
  }

  private fromBinary(binary) {
    const bytes = Uint8Array.from({ length: binary.length }, (element, index) => binary.charCodeAt(index))
    const charCodes = new Uint16Array(bytes.buffer)

    let result = ''
    charCodes.forEach(char => {
      result += String.fromCharCode(char)
    })
    return result
  }

  private encode(value: string) {
    return btoa(this.toBinary(value))
  }
  private decode(value: string) {
    return this.fromBinary(atob(value))
  }
  private encodeKey(key: string) {
    return this.encode(key.toLowerCase())
  }
  private encodeValue(value: object | string | number | Date | Array<any> | boolean) {
    return this.encode(JSON.stringify(value))
  }
  private decodeValue(value: string): object | string | number | Date | Array<any> | boolean {
    const decodedValue = this.decode(value)
    return JSON.parse(decodedValue === 'undefined' ? 'null' : decodedValue)
  }
}
