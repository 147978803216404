import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { EMPTY, from, Observable, throwError } from 'rxjs'
import { catchError, map, switchMap } from 'rxjs/operators'
import { SessionStorageKeys } from 'src/app/shared/constants/session-storage-keys'
import { StorageKeys } from 'src/app/shared/constants/storage-keys'
import { retrieveAndSetLanguage } from 'src/app/shared/helpers/language'
import { KtechHttpResponse, KtechHttpResponseStatus } from 'src/app/shared/models/ktech-http-response'
import { environment } from 'src/environments/environment'

import { AuthService } from '../auth/auth.service'
import { LocalStorageService } from '../storage/local-storage.service'
import { SessionStorageService } from '../storage/session-storage.service'
import { UtilitiesService } from '../utilities/utilities.service'

@Injectable()
export class InterceptorService implements HttpInterceptor {
  userloggedOut: boolean
  constructor(
    private readonly authService: AuthService,
    private readonly localStorage: LocalStorageService,
    private readonly sessionStorage: SessionStorageService,
    public readonly router: Router,
    private readonly utilitiesService: UtilitiesService,
    private readonly translate: TranslateService
  ) {
    translate.setDefaultLang('en-US')
    retrieveAndSetLanguage('en-US', this.translate)

    this.translate.onLangChange.subscribe(language => retrieveAndSetLanguage(language.lang, this.translate))
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // const isLogin = req.url.indexOf('login') >= 0
    let headers = req.headers
      .set('subdomain', environment.subdomain)
      .set('Authorization', `Bearer ${this.authService.keycloak?.token as string}`)
    // const sessionId = this.localStorage.get(StorageKeys.sessionId) as string;
    if (environment.keycloakRealm) {
      headers = headers.set('auth-subdomain', environment.keycloakRealm)
    }
    const origin = this.sessionStorage.get(SessionStorageKeys.origin) as string
    // const channel = this.sessionStorage.get(SessionStorageKeys.channel) as string
    // const language = this.sessionStorage.get(SessionStorageKeys.language) as string

    const selectedLanguage: any = this.sessionStorage.get(SessionStorageKeys.selectedLanguage)
    const selectedLanguageId = selectedLanguage?.id
    const authToken = this.localStorage.get(StorageKeys.authToken) as string
    headers = headers.set('Auth-Token', String(authToken))

    // const selectDepartment = this.localStorage.get(
    //   StorageKeys.selectedDepartment
    // ) as any;
    // if (isLogin || !sessionId) {
    //   if (origin) {
    //     headers = headers.set("originpath", origin);
    //   }
    // } else {
    if (origin) {
      headers = headers.set('originpath', String(origin))
    } else {
      // headers = headers
      //   .set("sessionid", sessionId)
      //   .set("X-CSRF-Token", sessionId);
    }
    // }
    // if (selectDepartment) {
    //   headers = headers.set("department", selectDepartment._Id);
    // }
    if (selectedLanguageId) {
      headers = headers.set('language', String(selectedLanguageId))
    }

    const dupReq = req.clone({
      headers: headers
    })
    return next.handle(dupReq).pipe(
      map((event: HttpEvent<any>) => this.mapHttpResult(event)),
      catchError(err => {
        if (err?.error?.errorCode && err.status != HttpStatusCodes.Unauthorized) {
          if (err?.error?.errorCode == '4048') {
            this.authService.setUserInfoMessage('ClientExpired')
          } else if (err?.error?.errorCode == '4050') {
            this.authService.setUserInfoMessage('UserLimitExceeded')
          } else if (err?.error?.errorCode === '4063') {
            this.utilitiesService.errorHandlerMessage(
              this.utilitiesService.capitalizeFirstLetter(Object.values(err.error.message[0].constraints)[0])
            )
          } else {
            this.utilitiesService.errorHandlerMessage(this.translate.instant('ERROR_CODE.' + err.error.errorCode))
          }
        }
        switch (err.status) {
          case HttpStatusCodes.UserNotFound:
            if (HttpStatusCodes.UserNotFound == err.error.status) {
              this.utilitiesService.errorHandlerMessage(err.error.message)
            }
            break
          case HttpStatusCodes.Unauthorized:
            if (HttpStatusCodes.Unauthorized == 401) {
              return from(this.authService.keycloak.updateToken(30)).pipe(
                switchMap(() => {
                  const refreshedClone = req.clone({
                    headers: headers.set('Authorization', `Bearer ${this.authService.keycloak.token as string}`)
                  })
                  return next.handle(refreshedClone).pipe(map((event: HttpEvent<any>) => this.mapHttpResult(event)))
                }),
                catchError(() => {
                  this.utilitiesService.errorHandlerMessage(
                    'Session has timed out! Please login to perform this action'
                  )
                  this.authService?.keycloak.logout()
                  return EMPTY
                })
              )
            }
            break
          // if (!this.userloggedOut) {
          //   if (
          //     err.error &&
          //     err.error.messages &&
          //     err.error.messages.length
          //   ) {
          //     //  alert(err.error.messages.join(","));
          //     this.utilitiesService.errorHandlerMessage(
          //       err.error.messages.join(",")
          //     );
          //   } else {
          //     //  alert(`Session expired\nPlease login again`);
          //     this.utilitiesService.errorHandlerMessage(
          //       `Session expired\nPlease login again`
          //     );
          //     alert("test");
          //   }
          //   this.localStorage.clearAll();
          //   this.router.navigate(["/login"]);
          //   window.location.reload();
          // }
          // this.userloggedOut = true
          // break
          case HttpStatusCodes.LimitExceeded:
          case HttpStatusCodes.ContentNotAvailable:
            if (err.error && err.error.messages && err.error.messages.length) {
              //  alert(err.error.messages.join(","));
              this.utilitiesService.errorHandlerMessage(err.error.messages.join(','))
            }
            break
          case HttpStatusCodes.Badrequest:
            // alert('An error occured');
            break
        }

        return throwError(err)
      })
    )
  }
  private mapHttpResult(event: HttpEvent<any>) {
    if (event instanceof HttpResponse) {
      if (event.url.indexOf(environment.apiBase) === -1) {
        this.userloggedOut = false
        return event
      }
      const _res = event.body as KtechHttpResponse
      if (_res.statusCode === KtechHttpResponseStatus.OK && (_res.result !== undefined || _res.result !== null)) {
        const _event = event.clone({
          body: _res.result
        })
        this.userloggedOut = false
        // this.userService.updateLogoutDate(moment().add(1, 'day'))
        return _event
      } else if (_res !== undefined || _res !== null) {
        const _event = event.clone({
          body: _res
        })
        // this.userloggedOut = false
        return _event
      } else {
        const error = { status: _res.statusCode, error: _res.error }
        throw error
      }
    }
    return event
  }
}

export enum HttpStatusCodes {
  Unauthorized = 401,
  ContentNotAvailable = 410,
  UserNotFound = 422,
  LimitExceeded = 429,
  Badrequest = 400,
  ValidationError = 400
}
