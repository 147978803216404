import { OverlayModule } from '@angular/cdk/overlay'
import { APP_BASE_HREF } from '@angular/common'
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import { APP_INITIALIZER, NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatDialogModule } from '@angular/material/dialog'
import { MatIconModule } from '@angular/material/icon'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { Router } from '@angular/router'
import { NgxJitsuModule } from '@jitsu/angular'
import { TranslateModule } from '@ngx-translate/core'
import * as Sentry from '@sentry/angular-ivy'
import { NgxUnleashProxyClientModule } from '@snowfrog/ngx-unleash-proxy-client'
import { EditorModule } from '@tinymce/tinymce-angular'
import { MonacoEditorModule } from 'ngx-monaco-editor'
import { ToastrModule } from 'ngx-toastr'
import { environment } from 'src/environments/environment'

import { AppComponent } from './app.component'
import { AppRoutingModule } from './app-routing.module'
import { CoreModule } from './core/core.module'
import { AuthService } from './core/services/auth/auth.service'
import { InterceptorService } from './core/services/http/interceptor.service'
import { initializeKeycloak } from './core/services/init/keycloak-init.factory'
import { DirtyFormGuard } from './core/services/modules/dirty-check-guard.service'
import { LocalStorageService } from './core/services/storage/local-storage.service'
import { UtilitiesService } from './core/services/utilities/utilities.service'
import { NotFoundComponent } from './not-found/not-found.component'

@NgModule({
  declarations: [AppComponent, NotFoundComponent],
  imports: [
    BrowserModule,
    CoreModule.forRoot(),
    MatProgressBarModule,
    AppRoutingModule,
    HttpClientModule,
    MatSnackBarModule,
    OverlayModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    EditorModule,
    MatDialogModule,
    MatIconModule,
    TranslateModule,
    MonacoEditorModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 5000,
      progressBar: true,
      preventDuplicates: true,
      positionClass: 'toast-top-right'
    }),
    NgxUnleashProxyClientModule.init({
      url: environment.unleashUrl,
      clientKey: environment.unleashClientKey,
      appName: environment.subdomain,
      environment: environment.unleashEnvironment,
      disableRefresh: true
    }),
    NgxJitsuModule.forRoot({
      tracking_host: environment.jitsuConfig?.jitsuTrackingHost,
      key: environment.jitsuConfig?.jitsuClientKey,
      randomize_url: true

      // See Jitsu SDK parameters section for more options
    }),
    TranslateModule.forRoot({})
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [AuthService, LocalStorageService]
    },
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (cssService: UtilitiesService) => {
        return () => {
          cssService.loadCSS(environment.styleUrl)
          return Promise.resolve()
        }
      },
      multi: true,
      deps: [UtilitiesService]
    },
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
    {
      provide: APP_BASE_HREF,
      useValue: '/' + (window.location.pathname.split('/')[1] + '/' || '')
    },
    DirtyFormGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
